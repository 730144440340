const Auth = () =>
    import ('../components/auth/Auth')
const Login = () =>
    import ('../components/auth/Login')
const Registration = () =>
    import ('../components/auth/Registration')
const SendResetPassword = () =>
    import ('../components/auth/SendResetPassword')
const ResetPassword = () =>
    import ('../components/auth/ResetPassword')

export default {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    children: [{
            path: '/logowanie',
            name: 'Login',
            component: Login,
            meta: { onlyGuest: true }
        },
        {
            path: '/rejestracja',
            name: 'Registration',
            component: Registration,
            meta: { onlyGuest: true }
        },
        {
            path: '/reset-hasla',
            name: 'SendResetPassword',
            component: SendResetPassword,
            meta: { onlyGuest: true }
        },
        {
            path: '/reset-hasla/:code',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: { onlyGuest: true }
        }
    ]
}