<template>
    <Modal
        :key='"modalAlarmPopup"'
        :name='"modalAlarmPopup"'
        :adaptive='true'
        :scrollable='true'
        height='auto'
    >
        <div class="alarm-popup dish-tile is-not-basic">
            <div class="alarm-popup__actions">
                <a class="alarm-popup__close" @click='$modal.hide("modalAlarmPopup")'><i class="fas fa-times"></i></a>
            </div>
            <p>Przepraszamy, w dniu dzisiejszym restauracja czynna od 17:00.</p>
            <p>Zamów z kodem <strong>WIECZOR17</strong> a otrzymasz <strong>5zł</strong> zniżki.</p>
            <p>Obowiązuje od 90zł.</p>
        </div>
    </Modal>
</template>

<script>
export default {
	mounted () {
		this.$modal.show("modalAlarmPopup")
	}
}
</script>

<style lang="scss">
.alarm-popup {
    padding: 30px;

    &__actions {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        margin: 2.5px;
        font-size: 12px;
        color: var(--black);
    }
}
</style>