const AccountWrapper = () =>
    import ('../components/account/AccountWrapper')
const Account = () =>
    import ('../components/account/Account')

export default {
    path: '/konto',
    component: AccountWrapper,
    children: [{
        path: '',
        name: 'Account',
        component: Account,
        meta: { authRequired: true }
    }, ]
}