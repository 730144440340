import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import pl from './pl'

Vue.use(VueI18n)

export const defaultLocale = 'pl'

const messages = { pl, en }

export const i18n = new VueI18n({
	locale: localStorage.getItem('lang') || defaultLocale,
	fallbackLocale: defaultLocale,
	messages
})


