import router from '../../router'

export function countDeliveryDistance(order, settings) {
    return new Promise((resolve) => {
        // console.log(order, settings)

        // If order in restaurant distance is 0
        if (order.order_type != 'online') {
            resolve(0.0)
            return
        }

        const lat1 = settings.latitude
        const lon1 = settings.longitude
        const lat2 = order.latitude
        const lon2 = order.longitude

        if (!lat1 || lat1 == "" || lat1 < 0 || !lon1 || lon1 == "" || lon1 < 0 || !lat2 || lat2 == "" || lat2 < 0 || !lon2 || lon2 == "" || lon2 < 0) {
            if (order.address && order.address.length > 0) {
                resolve('???')
                return
            }

            resolve(0.0)
            return
        }

        // console.log('Google api call')

        const google = window.google
        const origin = { lat: lat1, lng: lon1 };
        const destination = { lat: lat2, lng: lon2 };

        const directions = new google.maps.DirectionsService();
        directions.route({
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            provideRouteAlternatives: true,
        }, (response, status) => {
            if (status !== "OK") {
                // console.log("Distance matrix error: " + status);
                resolve('???')
            } else {
                const distances = response.routes.map(r => r.legs.map(l => parseFloat(l.distance.value)).reduce((a, b) => a + b, 0) / 1000)
                resolve(Math.min(...distances));
            }
        })
    })
}

export function countDeliveryPrice(distance, deliveries, price, special_menu_min_price, is_admin) {
    // console.log('countDeliveryPrice', distance, deliveries, price, special_menu_min_price, is_admin)

    // Brak obszarów
    if (!deliveries || deliveries.length <= 0) {
        // console.log('brak obszarów')
        return [0.0, true]
    }
    // Niezidentyfikowana odległość
    const maxPrice = deliveries[deliveries.length - 1].price
    if (distance == '???') {
        // console.log('niezidentyfikowana odległość')
        return [maxPrice, true]
    }
    const delivery = deliveries.find(d => d.distance >= distance)
        // Adres poza obszarami dostawy
    if (delivery == undefined) {
        // console.log('Za daleko')
        return [0.0, is_admin, router.app.$t("order.error.too_far")]
    }
    // Osiągnięta darmowa dostawa
    if (price > delivery.free_delivery_above) {
        // console.log('darmowa dostawa')
        return [0.0, true]
    }
    // SpecialMenu minimalna cena nie osiągnięta
    if (special_menu_min_price > price) {
        return [delivery.price, is_admin, router.app.$t("order.error.min_price_special", { min: special_menu_min_price })]
    }
    // Cena minimalna nieosiągnięta
    if (delivery.min_order_price > price) {
        // console.log('nie osiagnieto minimalnej ceny', delivery)
        return [delivery.price, is_admin, router.app.$t("order.error.min_price", { min: delivery.min_order_price })]
    }

    // Cena dostawy
    return [delivery.price, true, router.app.$t("order.error.free", { free: delivery.free_delivery_above })]
}

export async function checkFreeDeliveryAreas(price, order, freeDeliveryAreas, is_admin) {
    for(let i in freeDeliveryAreas) {
        const fda = freeDeliveryAreas[i]
        const distance = await countDeliveryDistance(order, fda)
        if (distance <= fda.distance) {
            // Znajdujemy się w obszarze dostawy darmowej
            if (order.price >= fda.min_order_price) {
                return [0.0, true]
            } else {
                return [0.0, is_admin, router.app.$t("order.error.min_price", { min: fda.min_order_price })]
            }
        }
    }

    return price
}