<template>
    <picture>
        <source v-if="webp" type="image/webp" :srcset="loadImagePath(webp)" />
        <source v-if="other" :type="`image/${ext}`" :srcset="loadImagePath(other)" />
        <template v-if="lazyImg">
            <img v-if="other" v-lazy="loadImagePath(other)" :alt="alt" :class='ownClass' :style="ownStyle" />
        </template>
        <template v-else>
            <img v-if="other" :src="loadImagePath(other)" :alt="alt" :class='ownClass' :style="ownStyle" />
        </template>
    </picture>
</template>

<script>
export default {
    props: ['name', 'ext', 'alt', 'ownClass', 'ownStyle', 'lazyImg'],
    computed: {
        webp () {
            return this.name ? `${this.name}.webp` : false
        },
        other () {
            return this.name && this.ext ? `${this.name}.${this.ext}` : false
        },
        lazyObject () {
            let lazy = {}
            if (this.lazyImg) {
                if (this.webp) {
                    lazy.webp = this.loadImagePath(this.webp)
                }
                if (this.other) {
                    lazy.image = this.loadImagePath(this.other)
                }
            }
            return lazy
        }
    },
    methods: {
        loadImagePath(name) {
            return name ? require(`../../assets/img/${name}`) : ''
        }
    },
}
</script>

<style lang="scss" scoped>
img[src*="loading"] {
    width: auto;
    height: auto;
    object-fit: scale-down;
}
</style>