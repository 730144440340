import { login, logout, updateUserData, validateToken } from '../../api/api'
import router from '../../router'

const state = {
    user: null
}

const getters = {
    current_user: state => state.user,
}

const mutations = {
    login(state, payload) {
        state.user = payload
    },
    logout(state) {
        state.user = null
    },
    updateUser(state, payload) {
        state.user = {...state.user, ...payload }
    }
}

const actions = {
    login({ commit }, payload) {
        return login(payload)
            .then(res => {
                commit('login', res.data.data)
            })
    },
    logout({ commit }) {
        return logout()
            .then(() => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('client')
                localStorage.removeItem('tokenType')
                localStorage.removeItem('uid')

                commit('order/resetOrder', null, { root: true })

                router.push('/').catch(() => {});

                commit('logout')
            })
            .catch(() => {
                window.location.reload()
            })
    },
    updateData({ commit }, payload) {
        return updateUserData(payload)
            .then(res => {
                commit('login', res.data.data)
            })
    },
    validateToken({ commit, dispatch }) {
        return validateToken()
            .then(res => {
                commit('login', res.data.data.user)
                dispatch('settings', res.data.data.settings, { root: true })
                dispatch('pageLoad', null, { root: true })
            })
            .catch(error => {
                dispatch('settings', error.response.data.data.settings, { root: true })
                dispatch('pageLoad', null, { root: true })
            })
    },
    setData({ commit }, payload) {
        commit('updateUser', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}