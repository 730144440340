<template>
    <div class="spinner__wrapper" :style='{width: size + "px", height: size + "px"}'>
        <webp-image name="spinner-fg" ext="png" alt="Wczytywanie" ownClass="spinner__item" :ownStyle='{width: size + "px", height: size + "px"}' :lazyImg="false" />
        <webp-image name="spinner-bg" ext="png" alt="Wczytywanie" ownClass="spinner spinner__item" :ownStyle='{width: size + "px", height: size + "px"}' :lazyImg="false" />
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: 50,
        }
    }
}
</script>

<style lang="scss">
.spinner {
    &__wrapper {
        display: flex;
        position: relative;

        margin: 0 auto;
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
    }

    -webkit-animation:spin 2s ease-in-out infinite;
    -moz-animation:spin 2s ease-in-out infinite;
    animation:spin 2s ease-in-out infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>