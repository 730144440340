import { defaultLocale } from '../i18n'

export function trans (obj, key) {
	const lang = localStorage.getItem('lang') || defaultLocale
	const keyLang = key + '_' + lang
	if (!obj[keyLang]) {
		return obj[key]
	}
	return obj[keyLang]
}
