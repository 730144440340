<template>
	<Cover>
		<div class="offline is-fullscrean">
			<div class='offline__site-name'>{{ $t('name') }}</div>
			<Spinner :size='120' />
			<br>
			<br>
			<br>
			<h1>{{ $t('offline.info1') }}</h1>
			<h3>{{ $t('offline.info2') }}</h3>
		</div>
	</Cover>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	data: () => ({
		interval: null,
    }),
    methods: {
        ...mapActions({
            validateToken: 'user/validateToken',
        }),
        startWorker () {
            this.interval = setInterval(this.validateToken, process.env.VUE_APP_WORKER_INTERVAL / 2)
        },
		stopWorker () {
            clearInterval(this.interval)
            this.interval = null
		},
    },
    mounted () {
        this.startWorker()
    },
	beforeDestroy () {
		this.stopWorker()
		this.$router.go(-1)
	}
}
</script>

<style lang="scss">
.offline {
	flex-direction: column;

	&__site-name {
		font-size: 75px;
		font-family: var(--font-header);
		margin-bottom: 50px;
	}
}
</style>