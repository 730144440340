import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import Toastr from 'vue-toastr'
import { i18n, defaultLocale } from './i18n'
import wysiwyg from 'vue-wysiwyg'
import VModal from 'vue-js-modal'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueLazyload from 'vue-lazyload'
import JsonViewer from 'vue-json-viewer'

import './assets/css/main.scss'
import 'vue-js-modal/dist/styles.css'
import 'vue-json-viewer/style.css'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VTooltip, {
    defaultDelay: 300,
    defaultOffset: 5
})
VTooltip.enabled = window.innerWidth > 768
Vue.use(Toastr)
Vue.use(wysiwyg, {
    hideModules: { 'image': true, 'table': true, 'code': true },
    maxHeight: '500px'
})
Vue.use(VModal)
Vue.use(VueLazyload, { error: '/loading.svg', loading: '/loading.svg' })
Vue.use(JsonViewer)

// Filrters
Vue.filter('yesno', (value, vtrue = router.app.$t('sayyes'), vfalse = router.app.$t('sayno')) => value ? vtrue : vfalse)
Vue.filter('img_url', (url) => process.env.VUE_APP_IMG_URL_PREFIX + url)
Vue.filter('price', (value) => parseFloat(value).toFixed(2) + 'zł')
Vue.filter('distance', (value) => (value === undefined || value === '???' || value < 0 ? '???' : '~' + value.toFixed(2)) + 'km')
Vue.filter('capitalcase', (value) => value.charAt(0).toUpperCase() + value.slice(1))

export const defaultReqInterceptors = config => {
    config.headers['access-token'] = localStorage.getItem('accessToken')
    config.headers['client'] = localStorage.getItem('client')
    config.headers['token-type'] = localStorage.getItem('tokenType')
    config.headers['uid'] = localStorage.getItem('uid')
    config.headers['lang'] = localStorage.getItem('lang') || defaultLocale

    return config
}

export const defaultResInterceptors = response => {
    const accessToken = response.headers['access-token']
    const client = response.headers['client']
    const tokenType = response.headers['token-type']
    const uid = response.headers['uid']

    if (accessToken && client && tokenType && uid) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('client', client)
        localStorage.setItem('tokenType', tokenType)
        localStorage.setItem('uid', uid)
    }

    if (store.getters['serverOffline']) {
        // Set server online
        store.dispatch('serverOnline')
    }

    return response
}

export const defaultResInterceptorsError = error => {
    if (!error.response) {
        store.dispatch('serverOffline')
        router.push('/')
        return
    }

    if (error.response.statusText === 'Forbidden') {
        router.app.$toastr.e(router.app.$t('messages.accessDenied'))

        store.getters['user/current_user'] && store.getters['user/current_user'].is_staff ?
            router.push({ name: 'Admin' }) :
            router.push('/')
        return
    }

    if (error.response.statusText === 'Locked') {
        router.app.$toastr.e(router.app.$t('messages.banned'))

        localStorage.removeItem('accessToken')
        localStorage.removeItem('client')
        localStorage.removeItem('tokenType')
        localStorage.removeItem('uid')

        if (store.getters['user/current_user']) {
            store.dispatch('user/logout')
        } else {
            router.push('/')
        }

        return
    }

    return Promise.reject(error)
}

function beforeMountGuards(meta, user, ipBanned = false) {
    if (ipBanned) {
        router.push('/')
        return
    }

    // If user already logged in and try to go to onlyGuest route (mainly auth related)
    if (meta.onlyGuest && user !== null) {
        router.push('/')
        return
    }
    // If guest try to go to authorize route
    if ((meta.authRequired || meta.onlyStaff) && user === null) {
        router.app.$toastr.i(router.app.$t('messages.mustLogin'))
        router.push({ name: 'Login' })
        return
    }

    // If user try to go to onlyStaff route
    if (meta.onlyStaff && !user.is_staff) {
        if (meta.allowDriver && user.is_driver) {
            return
        }
        router.app.$toastr.e(router.app.$t('messages.accessDenied'))
        router.push('/')
        return
    }

    // If moderator try to go to onlyAdmin route
    if (meta.onlyAdmin && !user.is_admin) {
        router.app.$toastr.e(router.app.$t('messages.accessDenied'))
        router.push('/')
    }
}

// GLOBAL COMPONENTS
const requireComponent = require.context(
    // The relative path of the components folder
    './components/global',
    // Whether or not to look in subfolders
    true
)

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
    )


    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    )
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeMount() {
        // Redirect subdomain
        // if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_NODE_ENV !== 'staging') {
        //     let hostPart = window.location.host.split('.')
        //     if (hostPart[0] != 'kebabowski' || (hostPart[0] === 'www' && hostPart[1] !== 'kebabowski')) {
        //         window.location = process.env.VUE_APP_FRONT_URL
        //     }
        // }

        this.$store.dispatch('user/validateToken')
            .then(() => {
                beforeMountGuards(this.$route.meta, this.$store.getters['user/current_user'])
            })
            .catch(() => {
                beforeMountGuards(this.$route.meta, null)
            })
    }
}).$mount('#app')