<template>
    <router-link :to='{name: "Cart"}' class="cart-badge">
        <div class="cart-badge__price">
            <i class="fas fa-shopping-cart"></i>
            <strong>
                <template v-if="price > 0">
                    {{ price | price }}
                    <template v-if="pointsPrice > 0"> + </template>
                </template>
                <template v-if="pointsPrice > 0">{{ pointsPrice }}pkt</template>
            </strong>
        </div>
    </router-link>
</template>

<script>
export default {
    props: ['price', 'pointsPrice'],
}
</script>

<style lang="scss">
.cart-badge {
    position: fixed;
    z-index: 100;
    bottom: 25px;
    right: 25px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;

    padding: 10px 20px;

    background-color: var(--red);
    box-shadow: 0px 0px 2.5px 0px var(--black-shadow);

    &:hover {
        color: var(--white);
        box-shadow: 0px 0px 5px 0px var(--black-shadow);
    }

    &__price {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 45px;
        font-family: var(--font-header);

        strong {
            margin-left: 25px;
            font-weight: 700;
        }
    }
}
</style>